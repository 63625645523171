#main-slider {
	opacity: 0;
}

.Slider {
	height: 100%;
	position: relative;

	.Slide {
		@include media(">tablet") {
			padding: 0 $padding2;
		}

		width: 100%;
		height: 100%;
		box-sizing: border-box;
		text-align: center;

		&__image {
			width: 100%;
			height: 100%;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50% 50%;
		}
	}
}

.owl-carousel {
	.owl-stage-outer,
	.owl-stage,
	.owl-item {
		height: 100%;
	}

	.owl-item {
		width: 100%;
	}

	.owl-stage-outer {
		z-index: 1000;
	}
}


@include media(">tablet") {
	.owl-carousel .owl-stage {
	  transition-timing-function: $timing-function !important;
	}
}

.owl-controls {
	display: none;

	@include media(">laptop") {
		@include absolute($top: 0, $left: 0, $right: 0, $bottom: 0);
		z-index: 1001;
		display: block;

		.owl-nav {
			height: 100%;
		}

		.owl-prev,
		.owl-next {
			display: block;
			width: 25%;
			height: 100%;

			opacity: 0;
			text-indent: -9999px;

			background-size: 26px;
			background-color: transparent;
			background-repeat: no-repeat;

			transition: opacity $speed $timing-function;

			&:hover {
				opacity: 1;
			}
		}

		.owl-prev {
			float: left;

			background-image: url('../img/arrow_prev.svg');
			background-position: 40px center;
		}

		.owl-next {
			float: right;

			background-image: url('../img/arrow_next.svg');
			background-position: calc(100% - 40px) center;
		}
	}
}