#header {
	opacity: 0;
	padding: 20px;

	@include media(">phone") {
        padding: 30px $padding2;
	}

	@include media(">tablet") {

		div {
			display: inline-block;
			vertical-align: middle;
		}
    }

	.logo {
		text-align: center;
		line-height: 1;

		a {
			display: inline-block;

			&:after {
		        display: none;
		    }
		}

		img {
			max-width: 100%;
		}
	}

	.contact-info {
		display: none;

		@include media(">tablet") {
			display: block;
			float: right;
			margin-top: 0;

			a {
				&:first-child {
					margin-right: 20px;
				}

				&:last-child {
					margin-left: 20px;
				}
			}
		}
	}
}