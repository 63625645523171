@import 'fonts',
		'variables',
		'mixins',
		'placeholders',
		'global';

@import "components/slider";

@import "partials/header",
		"partials/footer";

@import "ipad";