$color-primary:					#919191;

$color-body-font:				$color-primary;
$color-heading:					$color-primary;
$color-heading-link:			$color-primary;
$color-heading-link-hover:		$color-primary;

$color-input-placeholder:   	#83cbd6;

$color-link:                	#000000;
$color-link-hover:				#000000;

$speed:							250ms;

$padding1:						65px;
$padding2:						40px;
$padding3:						20px;

// Transitions
$easeInQuad:					cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic:					cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart:					cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint:					cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine:					cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo:					cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc:					cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack:					cubic-bezier(0.600, -0.280, 0.735, 0.045);

$easeOutQuad:					cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic:					cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart:					cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint:					cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine:					cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo:					cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc:					cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack:					cubic-bezier(0.175, 0.885, 0.320, 1.275);

$easeInOutQuad:					cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic:				cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart:				cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint:				cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine:					cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo:					cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc:					cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack:					cubic-bezier(0.680, -0.550, 0.265, 1.550);

$timing-function:				$easeInOutQuint;

$breakpoints:
	(
		phone: 480px,
		tablet: 760px,
		laptop: 992px,
		desktop: 1080px
	) !default;

$media-expressions:
	(
		screen: "screen",
        print: "print",
        retina2x: ( "(-webkit-min-device-pixel-ratio: 2)", "(min-resolution: 192dpi)" ),
        retina3x: ( "(-webkit-min-device-pixel-ratio: 3)", "(min-resolution: 350dpi)" )
    ) !default;