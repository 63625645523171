%button {
	position: relative;
	z-index: 100;

	display: inline-block;
	padding: 40px 95px;

	color: #fff;
	font-size: 21px;
	text-align: center;
	line-height: 1;

	border: 1px solid $color-primary;
	transition: color $speed $timing-function;

	&:before {
		content: "";
		@include absolute($top: -1px, $left: -1px, $right: -1px, $bottom: -1px);
		z-index: -100;

		background: $color-primary;
		@extend %blue-gradient;

		opacity: 1;
		transition: opacity $speed $timing-function;
	}

	&:hover {
		color: $color-primary;
		transition: color $speed $timing-function;

		&:before {
			opacity: 0;
			transition: opacity $speed $timing-function;
		}
	}

	@include media("<=phone") {
		padding: 30px 20px;
	    display: block;
	}
}

%clearfix {
	*zoom: 1;
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

%fontawesome {
	display: inline-block;

	&:before {
		font-family: FontAwesome;
	    font-style: normal;
	    font-weight: normal;
	    text-decoration: inherit;
	    font-size: 18px;
	    line-height: 1;
	    padding-right: 0.5em;
	}

	&:after {
		font-family: FontAwesome;
	    font-style: normal;
	    font-weight: normal;
	    text-decoration: inherit;
	    font-size: 18px;
	    padding-right: 0.5em;
	}
}

