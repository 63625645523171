#footer {
	opacity: 0;
	padding: 15px $padding3;

	.contact-info {
		display: inline-block;
		text-align: center;

		a {
			display: inline-block;

			&:first-child {
				margin-bottom: 10px;
				margin-right: 10px;
			}

			&:last-child {
				margin-top: 10px;
				margin-left: 10px;
			}
		}
	}

	.copyright,
	.video-link,
	.credits {
		display: none;
	}

	@include media(">tablet") {
        padding-left: $padding2;
        padding-right: $padding2;

        .contact-info {
			display: none;
        }

        .copyright,
		.video-link,
		.credits {
			display: inline-block;
			margin-top: 0;
			margin-right: 40px;
			margin-bottom: 0;
        }
    }

	text-align: center;
}