@charset "utf-8";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li, img,
fieldset, form, label, legend, input,
table, caption, tbody, tfoot, thead, tr, th, td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    list-style: none;
    text-decoration: none;
    outline: 0;
}
:focus { outline: 0; }
b, strong { font-weight: bold; font-weight: 600; }
label {cursor:pointer;}
header, aside, nav, footer, article, section { display: block; }

html {
	overflow: hidden;
    width: 100%;
    text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    min-height: 100vh;
    position: relative;
	overflow: hidden;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 1.4;
    color: $color-body-font;
    font-family: $font-family-body;
    -webkit-overflow-scrolling: touch;
}


a {
    position: relative;

    transition: color $speed $timing-function;
    color: $color-link;
    font-weight: 700;

    &:after {
        @include absolute($left: 0, $right: 0, $bottom: -5px);
        content: "";
        display: block;

        border-bottom: 1px solid #c6c6c6;
        opacity: 0;

        transition: opacity $speed $timing-function;
    }

    &:focus,
    &:hover,
    &:active {
        opacity: 1;
        color: $color-link-hover;

        &:after {
            opacity: 1;
        }
    }
}

a[href^=tel],
a[href^=fax],
a[href^=mailto] {

}
